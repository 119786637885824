<script setup lang="ts">
// import { SpeedInsights } from "@vercel/speed-insights/nuxt"
import { Vue3Lottie } from 'vue3-lottie'
import loadingJson from "@/assets/lottie/load.json";
useHead({
  titleTemplate: "Faisal Affan"
})

useSeoMeta({
  ogImage: '/og-image.png',
})
console.log("%c\n           /\\_____/\\ \r\n          /  o   o  \\ \r\n         ( ==  ^  == ) \r\n          )         ( \r\n         (           ) \r\n        ( (  )   (  ) ) \r\n       (__(__)___(__)__)\n    ", "font-weight:bold; font-size:15px;color:#ff4747");
console.log("%c" + "Welcome To My Website!!", "font-size: 5vh");

const nuxtApp = useNuxtApp();
const loading = ref(true);
nuxtApp.hook("page:loading:start", () => {
  loading.value = true;
});
nuxtApp.hook("page:loading:end", () => {
  setTimeout(() => {
    loading.value = false;
  }, 100);
});
const { locale } = useI18n()
switch (navigator.language) {
  case 'id':
    locale.value = 'id'
    break;

  default:
    locale.value = 'en'
    break;
}
</script>

<template>
  <!-- <SpeedInsights /> -->
  <NuxtLoadingIndicator />
  <div style="height: 100vh; width: 100%; position: fixed; background-color: whitesmoke; z-index: 1000;" v-if="loading">
    <client-only>
      <Vue3Lottie :animationData="loadingJson" height="50vh" width="50vh" style="margin: 0 auto; height: 100vh;" :speed="1"/>
    </client-only>
  </div>
  <a-layout>
    <LazyNuxtPage />
  </a-layout>
</template>
